import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';

import {
  HYMNS,
  AUTHORS,
  COMPOSERS,
  TUNES,
} from '../globals';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

const NavigationContent = () => {
  const classes = useStyles();

  const navItems = [
    { text: 'Hymns', to: `/${HYMNS}` },
    { text: 'Authors', to: `/${AUTHORS}` },
    { text: 'Composers', to: `/${COMPOSERS}` },
    { text: 'Tunes', to: `/${TUNES}` },
  ];

  return (
    <div className="header">
      <div className="u-relative">
        <Typography className={classes.title} variant="h6" noWrap>
          Spiritual Songs
        </Typography>
        <Link className="u-abs-cover" to="/" />
      </div>
      <nav>
        <ul className="u-list-style-none navigation__nav">

          {navItems.map(({ text, to }, i) => (
            <li className="navigation__nav-item u-relative" key={i}>
              {text}
              <Link className="u-abs-cover" to={to} />
            </li>
          ))}

        </ul>
      </nav>
    </div>
  );
};

const NavigationBar = ({ position, contained }) => (
  <AppBar position={position} color="secondary">
    <Toolbar>

      {contained && (
        <Container>
          <NavigationContent />
        </Container>
      )}

      {!contained && <NavigationContent />}

    </Toolbar>
  </AppBar>
);

NavigationBar.propTypes = {
  position: PropTypes.string,
  contained: PropTypes.bool,
};

NavigationBar.defaultProps = {
  position: 'static',
  contained: false,
};

export default NavigationBar;
